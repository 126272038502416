














































/** デコレータ利用のためComponent, Vueをimport **/
import { Component, Vue } from 'vue-property-decorator';
/** 画面で必要なVuexの各stateをimport **/
import hexabaseState from '@/store/hexabase';
/** ドメイン単位の設定ファイルから必要な設定をimport **/
import {
  documentDBInitStatusId,
  documentDBFailureStatusMessage,
  documentDBName,
  documentDBRegisterInitActionDisplayId,
  documentDBNewActionDisplayId
} from '@/domains/documentManagement/constants/constants';
/** 画面単位の設定ファイルから必要な設定をimport **/
import localDisplayConfig from '@/domains/documentManagement/constants/documentRegister';
/** バックエンド設定で賄えないフロント定義によるフィールド入力ルールをimport **/
import { inputRules } from '@/domains/documentManagement/constants/inputCheck';
/** 画面で利用するバックエンド設定のimport **/
import {
  FieldChildrenProp,
  ActionFieldSetting,
  GetInputFieldsActions,
  RegistData,
  ActionList
} from '@/services/hexabase/types.d';
/** 画面で利用するモデルファイルをimport **/
import DocumentModel from '@/domains/documentManagement/models/documentModel';
/** 画面で利用するコンポーネントをimport 下部に存在する＠Componentにも記載する **/
import Header from '@/components/common/modules/Header.vue';
import SideMenu from '@/components/common/modules/SideMenu.vue';
import PageInfoBar from '@/components/common/modules/PageInfoBar.vue';
import CreateBasicInfo from '@/domains/documentManagement/components/CreateBasicInfo.vue';
import TransitionDialog from '@/components/common/modules/TransitionDialog.vue';
import ExecAction from '@/components/common/modules/ExecAction.vue';
/** 画面で利用する共通ライブラリをimport **/
// import { DateTime } from 'luxon';

@Component({
  components: {
    Header,
    SideMenu,
    PageInfoBar,
    CreateBasicInfo,
    TransitionDialog,
    ExecAction
  }
})
export default class DocumentRegister extends Vue {
  // 各種設定値をリテラル宣言（ ※コピー後、必要に応じてリテラル値を変更 ）
  readonly baseTranDatabaseName = documentDBName; // 対象のデータベース名(ja)
  readonly initialDisplayActionDisplayId = documentDBRegisterInitActionDisplayId;
  readonly newActionDisplayId = documentDBNewActionDisplayId; //「登録する」ボタンに紐づける新規登録用アクションID
  // =======================================================================

  // モデルをセット
  readonly basicModel = new DocumentModel();
  // アプリIDをセット
  readonly projectId = hexabaseState.applicationId;

  // 各入力フィールド情報格納プロパティ
  basicFieldsData: Array<FieldChildrenProp> = [];
  basicFieldCols = '';

  // dbID格納プロパティ
  mainDsId = '';

  // 登録用情報格納プロパティ
  registData: RegistData = {
    item: {},
    return_item_result: true, // true指定すると、登録されたアイテム情報を返します
    related_ds_items: {} // 関連するデータストアの新規・更新・削除を指定  詳細は以下を参照
  };

  // マスタデータ関連格納プロパティ
  private masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } } = {};

  // アイテム登録後ダイアログ
  dialogStatus = false;
  isLoading = false;
  registerText = this.basicModel.deepCopy(localDisplayConfig.registerFinishText);
  buttons: Array<{ [k: string]: string | number }> = this.basicModel.deepCopy(
    localDisplayConfig.registerFinishAction
  );

  // 発注の初期ステータス
  displayStatus = '';
  // 登録可能権限
  registAuthority = false;
  // 画面フッター部に表示されるメッセージ
  guideMessage = '';
  // 画面下部に表示する利用可能な新規登録アクション
  availableNewAction: ActionList[] = [];

  async created() {
    try {
      // ローディングをセット
      hexabaseState.setIsLoading(true);
      // ログイン時に保持したデータストアの日本語物理名をキーとしたオブジェクトからdatastore_id(display_idではない文字列)を取得する
      this.mainDsId = hexabaseState.datastoreIds[this.baseTranDatabaseName];
      // マスターデータを取得しグローバル変数に格納
      await this.basicModel.getMasterData();
      this.masterData = this.basicModel.getMaster();
      // ステータスリストを取得
      // 入力フィールド情報を取得
      const [statusRes, basicField] = await Promise.all([
        this.basicModel.getStatusList(this.projectId, this.mainDsId),
        this.basicModel.getBasicFieldsRegister(
          this.projectId,
          this.mainDsId,
          this.initialDisplayActionDisplayId,
          [this.newActionDisplayId]
        )
      ]);
      // 利用可能な新規登録アクション
      this.availableNewAction = basicField.availableNewAction;
      // 権限を判定する
      // 利用可能な新規登録アクションを取得できていないなら登録権限を持たない
      this.registAuthority = this.availableNewAction.length > 0 ? true : false;
      this.guideMessage = this.registAuthority
        ? localDisplayConfig.authorityValidMessage
        : localDisplayConfig.authorityInvalidMessage;
      this.basicFieldCols = basicField.basicFieldCols;
      // inputFieldsはフィールドに対する必須などのルール付与のための設定を含むものを指定する
      this.basicFieldsData = this.modifyFields(basicField.basicFieldsData, basicField.inputFields);
      // ステータスが取得できた場合は基本情報コンポーネントのPropdisplayStatusにステータス初期値をセットする
      if (statusRes.length > 0) {
        const statusList = statusRes[0].statuses;
        this.displayStatus =
          statusList !== undefined && statusList.find(v => v.status_name === documentDBInitStatusId)
            ? statusList.find(v => v.status_name === documentDBInitStatusId)!.status_name
            : documentDBFailureStatusMessage;
      }
    } catch (e) {
      this.setError(e as string | object);
    } finally {
      hexabaseState.setIsLoading(false);
    }
  }

  /**
   * 子コンポーネントからEmitされる値インサート用に加工（基本情報）
   * 主にPropsの変更を行う
   * @module catchInputBasic
   * @param response { name: string; value: string } - 子コンポーネントより渡される値
   * @return {void}
   */
  catchInputBasic(response: { name: string; value: string }): void {
    const index = this.basicFieldsData.findIndex(v => v.display_id === response.name);
    switch (this.basicFieldsData[index].component) {
      case 'MasterSelectControl':
      case 'MasterSelectSetControl':
      case 'MasterSelectMultiControl':
      case 'MasterSelectMultiSetControl':
        this.$set(this.basicFieldsData[index].props!, 'value', { item_id: response.value });
        break;
      default:
        this.$set(this.basicFieldsData[index].props!, 'value', response.value);
    }

    // 入力に紐付け入力チェックがある場合
    if (inputRules.regist !== undefined && inputRules.regist !== undefined) {
      const validateRules = this.basicModel.addLinkingRuleByInput(
        inputRules.regist,
        response,
        this.basicFieldsData
      );
      if (validateRules.length > 0) {
        for (const ruleIndex in validateRules) {
          this.$set(
            this.basicFieldsData[validateRules[ruleIndex].key].props!,
            'rules',
            validateRules[ruleIndex].rules
          );
        }
      }
    }
    // 入力値から登録データを生成
    const name = response.name;
    const value = response.value;
    this.registData.item[name] = value;
  }

  /**
   * ルーター設定ファイルよりパンくずリスト取得
   * @module getBreadCrumbs
   * @return {array} - breadcrumbs 情報
   */
  getBreadCrumbs(): [] {
    if (
      this &&
      this.$route &&
      this.$route.meta &&
      this.$route.meta.breadcrumbs &&
      this.$route.matched.some(obj => obj.meta.breadcrumbs)) {
      return this.$route.meta.breadcrumbs;
    }
    return [];
  }

  /**
   * アクションパネルのボタンを押下した際の次処理を進める
   * @param {string} - イベント情報として押下したボタン種類が返ってくる
   */
  async execAction(type: string) {
    switch (type) {
      case 'cancel': {
        // 前画面に戻る
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        break;
      }
      default: {
        try {
          // ローディングをセット
          hexabaseState.setIsLoading(true);
          // 押下された新規登録アクションを新規登録のpayloadに指定
          this.registData.action_id = type;
          // 登録APIを実行
          const result = await this.basicModel.registerLinkedItems(
            hexabaseState.applicationId,
            this.mainDsId,
            this.registData
          );
          // 登録API実行結果についてハンドリング（問題あればエラー内容がthrowされる）
          this.basicModel.apiErrorHandle('CREATEITEM', result);
          // 登録が成功した場合は、作成したアイテムの詳細画面への遷移ボタンを生成する
          this.buttons.map(v => {
            if (v.name === 'toEdit') {
              v.route = String(v.route).replace(':id', result.item_id);
            }
          });
        } catch (e) {
          this.registerText = 'エラーにより処理できませんでした';
          this.buttons.splice(0);
          this.buttons.push({ id: 1, type: 'cancel', name: 'cancel', value: '閉じる' });
        } finally {
          hexabaseState.setIsLoading(false);
          this.dialogStatus = true;
        }
        break;
      }
    }
  }

  /**
   * アイテムを登録後ダイアログ表示等を初期化します
   * @returns {void} - 内部プロパティ変更
   */
  registerFinish(): void {
    this.dialogStatus = false;
    this.isLoading = false;
  }

  /**
   * フィールド整形用関数をまとめた関数
   * @param data {Array<FieldChildrenProp> | Array<FieldChildrenProp[]>} - テーブルが持つフィールドデータ
   * @param inputFields {GetInputFieldsActions} - テーブルアクションに対応するフィールドデータ
   * @returns modifyInputAllow(data, inputFields.action_field_settings)
   */
  modifyFields(
    data: Array<FieldChildrenProp> | Array<FieldChildrenProp[]>,
    inputFields: GetInputFieldsActions
  ) {
    data = this.addInputRules(data as Array<FieldChildrenProp>, inputFields.action_field_settings);
    return this.modifyInputAllow(data, inputFields.action_field_settings) as Array<
      FieldChildrenProp
    >;
  }

  /**
   * テーブルが持つフィールドデータに対して
   * テーブルアクションに対応するフィールド設定から
   * 入力ルールを付与する
   * @param fields {Array<FieldChildrenProp>} - テーブルが持つフィールドデータ
   * @param fieldSetting {[k: string]: ActionFieldSetting} - テーブルアクションに対応するフィールド設定
   * @returns ルールが付与されたフィールド
   */
  addInputRules(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting }
  ) {
    return fields.map(field => {
      if (field.props === undefined) return field;

      const ruleArray = [];

      // Hexa側設定必須項目
      if (fieldSetting[field.field_id] !== undefined) {
        // 必須項目確認
        if (fieldSetting[field.field_id].mandatory) {
          const requiredName = field.component === 'SelectArea' ? 'selectRequired' : 'required';
          ruleArray.push(requiredName);
        }
      }

      // アプリ側設定項目
      if (inputRules !== undefined) {
        const row = inputRules.regist.find((val: { id: string }) => val.id === field.display_id);
        if (row !== undefined) {
          ruleArray.push(row.rule);
        }
      }

      if (field.props!['rules'] === undefined) {
        field.props!['rules'] = '';
      }
      if (ruleArray.length > 0) field.props!['rules'] = ruleArray.join('|');
      return field;
    });
  }

  /**
   * fieldsに対してfieldSettingの表示・入力・必須の設定情報を反映する
   * @param fields {Array<FieldChildrenProp>} - テーブルが持つフィールドデータ
   * @param fieldSetting {[k: string]: ActionFieldSetting} - テーブルアクションに対応するフィールド設定
   * @returns 表示・入力・必須の設定情報が反映されたフィールド
   */
  modifyInputAllow(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting }
  ) {
    return fields.map(field => {
      if (fieldSetting[field.field_id] !== undefined) {
        const row = fieldSetting[field.field_id];
        if (row.update && !field.props!.isDisabled) {
          field.props! = { ...field.props!, ...{ isDisabled: false, isReadonly: false } };
        }
        if (field.dataType === 'dslookup') {
          field.props!.isBtnVisible = true;
          field.props!.isLeftSideInputReadOnly = false;
        }
        field = {
          ...field,
          ...{
            show: row.show,
            update: row.update,
            mandatory: row.mandatory
          }
        };
      }
      return field;
    });
  }

  /**
   * API実行結果から受け取ったエラーメッセージを共通のエラーダイアログに表示する
   * @param errorMessage {string | object } - API実行結果から受け取ったエラーメッセージ
   * @returns {void}
   */
  setError(errorMessage: string | object): void {
    if (typeof errorMessage === 'object') errorMessage = this.basicModel.getDefaultErrorMessage();
    // エラーをキャッチした際共通エラーダイアログにセット
    this.$store.commit('hexabase/setIsError', true);
    this.$store.commit('hexabase/setErrorMessage', errorMessage);
  }
}
